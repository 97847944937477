[data-reach-dialog-content] {
  width: 90vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
  position: relative;
  border-radius: 6px;
}

@media (max-width: 1023px) {
  .ticket-modal {
    width: 100vw;
    margin: 0;
    padding: 0 !important;
  }

  .content {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
  }

  .content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  [data-reach-dialog-overlay] {
    overflow: hidden;
  }
}
