@font-face {
  src: url('./assets/fonts/gotham/Gotham-Book.otf');
  font-family: Gotham;
  font-weight: normal;
}

@font-face {
  src: url('./assets/fonts/gotham/Gotham-Medium.otf');
  font-family: Gotham;
  font-weight: bold;
}

* {
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  color: #333;
  font-size: 1em;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  object-fit: cover;
  object-position: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  cursor: pointer;
}

[data-reach-dialog-overlay] {
  z-index: 9999;
}

.Toastify__toast {
  overflow: hidden;

  border-radius: 6px;
  padding: 0;
}

.Toastify__toast .Toastify__close-button {
  margin-top: 4px;
  margin-right: 4px;
}

[data-reach-menu-popover] {
  z-index: 9999;
}

.fc-scrollgrid {
  background-color: white !important;
  min-width: 700px;
}

.fc-day-today .fc-daygrid-day-frame {
  background-color: #22c75950 !important;
  color: white !important;
}

.fc .fc-view-harness {
  overflow-x: auto;
}

.fc-day-today .fc-daygrid-day-events .fc-daygrid-event-harness {
  background-color: #aaa;
  border-radius: 6px;
  margin: 2px 4px;
}

.fc-daygrid-day-top .fc-daygrid-day-number {
  color: black;
}

.fc-daygrid-day-bottom {
  font-weight: bold !important;
}

.fc-day-today .fc-daygrid-day-events .fc-daygrid-day-bottom {
  color: black;
}

.fc .fc-daygrid-body,
.fc .fc-scrollgrid-section table {
  width: 100% !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  flex-wrap: wrap;
}

.fc .fc-toolbar-title {
  padding: 5px 0;
}

*[data-reach-dialog-overlay] {
  z-index: 9999;
}
